<template>
  <div class="page-wrapper shifts__wrapper">
    <div class="container">
      <div class="shifts__inner page-inner">
        <div class="shifts__title-wrapper page-title-wrapper">
          <div class="shifts__title page-title">Customers</div>
          <div @click="showAddCustomer" class="shifts__button button button--fill">Add New Customer</div>
        </div>
        <div class="shifts__table table">
          <div class="table__title">
            <div class="table__title-item">On/Off</div>
            <div class="table__title-item">Business Name</div>
            <div class="table__title-item">Contact Name</div>
            <div class="table__title-item">Address</div>
            <div class="table__title-item">Contact</div>
            <div class="table__title-item">Long & Lat</div>
          </div>
          <div class="table__main">
            <Customer v-for="customer in customers" :key="customer.id" :info="customer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Customer from '@/views/Admin/Customers/Customer'

export default {
  name: 'Customers',
  components: {Customer},
  data: () => ({
    customers: [
      {
        id: 1,
        active: false,
        businessName: 'Johny Cash',
        contactName: 'Johny Johnson ',
        address: 'Ontario M5H 2N1 Canada',
        phone: '+3232 2323 2233',
        email: 'dasdasdsa@adsdas.com',
        long: '234324.23423.234',
        lat: '46565.234234.2343',
      },
      {
        id: 2,
        active: true,
        businessName: 'Johny Cash',
        contactName: 'Johny Johnson ',
        address: 'Ontario M5H 2N1 Canada',
        phone: '+3232 2323 2233',
        email: 'dasdasdsa@adsdas.com',
        long: '234324.23423.234',
        lat: '46565.234234.2343',
      },
      {
        id: 3,
        active: true,
        businessName: 'Johny Cash',
        contactName: 'Johny Johnson ',
        address: 'Ontario M5H 2N1 Canada',
        phone: '+3232 2323 2233',
        email: 'dasdasdsa@adsdas.com',
        long: '234324.23423.234',
        lat: '46565.234234.2343',
      },
      {
        id: 4,
        active: true,
        businessName: 'Johny Cash',
        contactName: 'Johny Johnson ',
        address: 'Ontario M5H 2N1 Canada',
        phone: '+3232 2323 2233',
        email: 'dasdasdsa@adsdas.com',
        long: '234324.23423.234',
        lat: '46565.234234.2343',
      },
      {
        id: 5,
        active: false,
        businessName: 'Johny Cash',
        contactName: 'Johny Johnson ',
        address: 'Ontario M5H 2N1 Canada',
        phone: '+3232 2323 2233',
        email: 'dasdasdsa@adsdas.com',
        long: '234324.23423.234',
        lat: '46565.234234.2343',
      },
    ]
  }),
  methods: {
    showAddCustomer() {
      this.$root.$emit('ShowSidebar', {
        sidebarName: 'AddCustomer',
        sidebarStyles: 'width: 700px'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
